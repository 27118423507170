// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aktivitas-js": () => import("./../../../src/pages/aktivitas.js" /* webpackChunkName: "component---src-pages-aktivitas-js" */),
  "component---src-pages-contentful-aktivitas-slug-js": () => import("./../../../src/pages/{ContentfulAktivitas.slug}.js" /* webpackChunkName: "component---src-pages-contentful-aktivitas-slug-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontak-js": () => import("./../../../src/pages/kontak.js" /* webpackChunkName: "component---src-pages-kontak-js" */),
  "component---src-pages-produk-js": () => import("./../../../src/pages/produk.js" /* webpackChunkName: "component---src-pages-produk-js" */),
  "component---src-pages-tentang-kami-js": () => import("./../../../src/pages/tentang-kami.js" /* webpackChunkName: "component---src-pages-tentang-kami-js" */)
}

